@import "../../bower/normalize-scss/_normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 5, 2019 */

.hoverline-tab{
	width:617.88px;
	justify-content: space-between;
}

video{
	outline:none;
}

@font-face {
    font-family: 'nunito_blackregular';
    src: url('../fonts/nunito-black-webfont.woff2') format('woff2'),
         url('../fonts/nunito-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_blackitalic';
    src: url('../fonts/nunito-blackitalic-webfont.woff2') format('woff2'),
         url('../fonts/nunito-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunitobold';
    src: url('../fonts/nunito-bold-webfont.woff2') format('woff2'),
         url('../fonts/nunito-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunitobold_italic';
    src: url('../fonts/nunito-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/nunito-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_extraboldregular';
    src: url('../fonts/nunito-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/nunito-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_extrabolditalic';
    src: url('../fonts/nunito-extrabolditalic-webfont.woff2') format('woff2'),
         url('../fonts/nunito-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_extralightregular';
    src: url('../fonts/nunito-extralight-webfont.woff2') format('woff2'),
         url('../fonts/nunito-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_extralightitalic';
    src: url('../fonts/nunito-extralightitalic-webfont.woff2') format('woff2'),
         url('../fonts/nunito-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunitoitalic';
    src: url('../fonts/nunito-italic-webfont.woff2') format('woff2'),
         url('../fonts/nunito-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_lightregular';
    src: url('../fonts/nunito-light-webfont.woff2') format('woff2'),
         url('../fonts/nunito-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_lightitalic';
    src: url('../fonts/nunito-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/nunito-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunitoregular';
    src: url('../fonts/nunito-regular-webfont.woff2') format('woff2'),
         url('../fonts/nunito-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_semiboldregular';
    src: url('../fonts/nunito-semibold-webfont.woff2') format('woff2'),
         url('../fonts/nunito-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunito_semibolditalic';
    src: url('../fonts/nunito-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/nunito-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans_blackregular';
    src: url('../fonts/worksans-black-webfont.woff2') format('woff2'),
         url('../fonts/worksans-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sansbold';
    src: url('../fonts/worksans-bold-webfont.woff2') format('woff2'),
         url('../fonts/worksans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans_extraboldregular';
    src: url('../fonts/worksans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/worksans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans_extralightregular';
    src: url('../fonts/worksans-extralight-webfont.woff2') format('woff2'),
         url('../fonts/worksans-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans_lightregular';
    src: url('../fonts/worksans-light-webfont.woff2') format('woff2'),
         url('../fonts/worksans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans_mediumregular';
    src: url('../fonts/worksans-medium-webfont.woff2') format('woff2'),
         url('../fonts/worksans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sansregular';
    src: url('../fonts/worksans-regular-webfont.woff2') format('woff2'),
         url('../fonts/worksans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans_semiboldregular';
    src: url('../fonts/worksans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/worksans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sans_thinregular';
    src: url('../fonts/worksans-thin-webfont.woff2') format('woff2'),
         url('../fonts/worksans-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geneva';
    src: url('../fonts/Geneva.eot');
    src: url('../fonts/Geneva.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Geneva.woff2') format('woff2'),
        url('../fonts/Geneva.woff') format('woff'),
        url('../fonts/Geneva.ttf') format('truetype'),
        url('../fonts/Geneva.svg#Geneva') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/Merriweather-Bold.eot');
    src: url('../fonts/Merriweather-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Merriweather-Bold.woff2') format('woff2'),
        url('../fonts/Merriweather-Bold.woff') format('woff'),
        url('../fonts/Merriweather-Bold.ttf') format('truetype'),
        url('../fonts/Merriweather-Bold.svg#Merriweather-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}



$geneva : 'Geneva';
$mwbold : 'Merriweather';

$nunitoLight: 'nunito_lightregular';
$nunitoLightItalic: 'nunito_lightitalic';
$nunitoExtraLight: 'nunito_extralightregular';
$nunitoExtraLightItalic: 'nunito_extralightitalic';
$nunitoRegular: 'nunitoregular';
$nunitoRegularItalic: 'nunitoitalic';
$nunitoSemiBold: 'nunito_semiboldregular';
$nunitoSemiBoldItalic: 'nunito_semibolditalic';
$nunitoBold: 'nunitobold';
$nunitoBoldItalic: 'nunitobold_italic';
$nunitoExtraBold: 'nunito_extraboldregular';
$nunitoExtraBoldItalic: 'nunito_extrabolditalic';
$nunitoBlack: 'nunito_blackregular';
$nunitoBlackItalic: 'nunito_blackitalic';

$worksansThin: 'work_sans_thinregular';
$worksansLight: 'work_sans_lightregular';
$worksansExtraLight: 'work_sans_extralightregular';
$worksansRegular: 'work_sansregular';
$worksansMedium: 'work_sans_mediumregular';
$worksansSemiBold: 'work_sans_semiboldregular';
$worksansBold: 'work_sansbold';
$worksansExtraBold: 'work_sans_extraboldregular';
$worksansBlack: 'work_sans_blackregular';

html, body {
	height: 100%;
	font-family: $geneva;
	position: relative;
}
body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}
ul {
	padding-left: 0;
}
li {
	list-style: none;
}

$black: #2b2c2b;

@media screen and (max-width: 1024px) {
	.tag-btn{
		margin:0 auto;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
		margin: 7.5px 0;
    }
    .navbar-nav>li {
		float: none;
		margin-bottom: 3px;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
	}
	
    .collapse.in {
		display:block !important;
    }
}
.navbar-default {
	background-color: transparent;
	border: none;
	border-radius: 0;
	z-index: 9;
	max-height:80px;
	margin-bottom: 0;
	&.fixed {
		max-height:80px;
		height: 100%;
		color: #ffffff;
		position: fixed;
		width: 100%;
		background: white;
		.navbar-nav {
			
			li {
				a {
					color: #ffffff;
				}
			}
		}
		&.white {
			background: white;
			
		}
		@media screen and (max-width: 1024px) {
			.navbar-collapse {
				background: #ffffff;
				border: 1px solid #ccc;
				position: absolute;
				left: 0;
				width: 100%;
			}
			.navbar-nav {
				li {
					a {
						color: $black;
					}
				}
			}
		}
	}
	.navbody{
		display: flex;
		flex-direction: row;
		font-size:15px;
		max-height: 80px;
		.navbar-header{
			display:flex;
			background-color: transparent;
			.navbar-brand {
				height: unset;
				display: flex;
				display: -webkit-flex;
				align-items: center;
				padding:0;
				img {
					max-height: 31px;
					max-width: 117px;
				}
			}
		}
		.navbar-toggle {
			border: none;
			&:hover, &:focus {
				background-color: transparent;
			}
			.icon-bar {
				background: #cccccc;
			}
		}
		.navbar-nav {
			position: relative;
			height: 80px;
			display: flex;
			display: -webkit-flex;
			align-items: center;
			margin-left:40px;
			
			@media (max-width: 1024px) {
				display: inline-block;
				height: unset;
				height: unset;
			}
			hr{
				position: absolute;
				left: 0;
				right: 0;
				height: 3px;
				width: 0%;
				background-color: #ffb000;
				bottom: 0px;
				z-index: 3;
				margin: 0 !important;
				transition: all 0.3s ease-in;
			}
			li  {
				padding: 0;
				&.open{
					> a {
						background: white;
						color: #ffb000!important;
						// text-decoration: underline #ffb000;
					}
					.dropdown-menu {
						li {
							margin-left: 0;
							a {
								border-radius: 0;
								color: $black;
								background:white !important;
								border-bottom: 5px #ffffff solid;
								transition: all 0.3s ease-in;		
							}
						}
					}
				}
				&:hover,&.activate {
					> a {
						background: white !important;
						// text-decoration: underline #ffb000;
						// transition: border-color 0.3s linear;		
						// border-bottom: 5px #ffb000 solid;	
						color: #ffb000!important;
						
						&::before{
							width:100%;
						}
					}

				}
				a {
					// padding: 8px 5;
					color: $black;
					font-family: $geneva;
					i {
						padding-left: 5px;
						font-size: 12px;
					}
					
				}
				.dropdown-menu {
					background: #ffffff;
				}
			}
		}
		.left{
			display: flex;
			flex-direction: row;
			max-height: 80px;
			.email-link{
				display:flex;
				flex-direction: row;
				align-items:center;
				min-width: 203px;
				width:100%;
				justify-content: flex-end;
				a{
					color:#16638f;
					&:hover{
						opacity: 0.6;
					}
				}
			}
			
			.social{
				display:flex;
				flex-direction: row;
				align-items: center;    
				justify-content: flex-end;
				padding-top: 5px;
				min-width: 110px;
				width:100%;
				li{
					padding: 10px 0;
					margin-left: 5px;
					a{
						padding: 8px 5px;
						
						&:hover{
							opacity: 0.6;
						}
						i{
							color:#16638f;
							font-size:23px;
						}
					}
				}
			}
		}
	}
}
footer {
	background-color: #16638F;
	padding: 65px 0 15px 0;
	color: #ffffff;
	min-height: 421px;
	position: relative;
	.content{
		display: flex;
		flex-direction: row;
		min-height: 270px;
	}
	hr{
		opacity: 0.1;
		max-width:1280px;
	}
	.container{
		padding:0;
		.about{
			width:35%;
			display: flex;
			flex-direction: column;
			img{
				max-width: 150px;
				object-fit: cover;
				object-position: center;
				margin-bottom:30px;
			}
			.address{
				max-width:270px;
				margin-bottom:20px;
				line-height: 1.5;
				opacity: 0.6;
			}
			.contact{
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				max-width:300px;
				span,a{
					color:#02A0FF;
					&:active,&:hover{
						color:white;
					}
				}
			}
			.social{
				margin-top: 25px;
				display:flex;
				flex-direction: row;
				justify-content: space-between;
				max-width:50px;
				i{
					font-size:25px;
					color:white;
					&:hover{
						opacity: 0.6;
					}
				}

			}
		}
		.combine{
			width: 75%;
			display: flex;
			justify-content: space-evenly;
			.menu{
				width:45%;
				display: flex;
				flex-direction: row;
				div{
					padding:0 20px;
					margin-bottom:30px;
				}
				li{
					padding:5px 0;
					opacity: 0.6;
					a{
						line-height: 1.5;
						&:hover{
							color:#ffb000;
						}
					}
				}
			}
			.button-grp{
				width:35%;
				display:flex;
				flex-direction: column;
				.title{opacity: 1;}
				p{opacity: 0.6;line-height: 1.5;}
				input{     
					text-indent: 10.16px;
					height: 46px;
					border-radius: 50px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
				button{
					background:white;
					i{
						color:#16638f;
					}
				}
			}
		}
		.bottom{
			display:flex;
			justify-content: center;
			p{
				opacity:0.6;
			}
			a{
				&:hover{
					opacity: 0.6;
				}
			}
		}
	}

	// .grid {
	// 	grid-gap: 100px;
	// 	grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
	// 	margin-bottom: 50px;
		
	// }
	// .copyright {
	// 	font-family: $worksansExtraLight;
	// 	font-style: italic;
	// 	text-align: center;
	// }
	// .section-title {
	// 	font-family: $worksansBold;
	// 	font-size: 15px;
	// 	margin-bottom: 15px;
	// }
	// .footer-menu {
	// 	columns: 2;
	// 	-webkit-columns: 2;
	// 	-moz-columns: 2;
	// 	li {
	// 		a {
	// 			&:hover {
	// 				color: #6ac6c1;
	// 			}
	// 		}
	// 	}
	// }
	// .input-group {
	// 	background-color: #f5f5f5;
	// 	border-radius: 50px;
	// 	margin-bottom: 15px;
	// 	input {
	// 		background-color: #f5f5f5;
	// 		border: none;
	// 		height: 42px;
	// 		padding-left: 20px;
	// 		border-top-left-radius: 50px;
	// 		border-bottom-left-radius: 50px;
	// 		outline: none;
	// 		&:hover, &:focus {
	// 			box-shadow: none!important;
	// 		}
	// 	}
	// 	.input-group-btn {
	// 		color: $black;
	// 		&:hover {
	// 			i {
	// 				color: #8d8d8d;
	// 			}
	// 		}
	// 	}
	// }
	// @media (max-width: 475px) {
	// 	.grid {
	// 		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	// 	}
	// 	.section-title {
	// 		font-size: 20px;
	// 	}
	// 	.footer-menu, .description {
	// 		font-size: 18px;
	// 	}
	// }
}

.form-control{
	border: 0 !important;
	height: 58px !important;
}
.cookie-div{
	display:none;
	position: fixed;
    bottom: 0;
    width: 100%;
	background: #30A7B4;	
	min-height: 153px;	
	height: 15%;
	z-index: 10;
	.container{
		padding:0;
		width:1233px;
		.content{
			padding:30px 0;	
			img{
				object-fit: cover;
				object-position: center;
				max-width:200px;
			}
			p{
				color:white;
			}
			.body{
				display:flex;
				justify-content: space-between;
				align-content: center;
				flex-direction: column;
				div{
					display:flex;
					justify-content: space-between;
					p{
						opacity: 0.8;
						width:100%;
						max-width: 1048px;
					}
					
					button{
						max-width: 129.7px;
						width:100%;
						max-height:44.3px;
						border-radius: 50px;
						color:#30A7B4;
						border:0;
						font-size:16px;
						transition: all 0.3s ease-in;
						background:white;
						&:hover{
							background: #16638f;
							color: #fff;
							border-color: #16638f;
						}
					}
				}
			}
		}
	}
}

.palette-border {
	height: 21px;
	background: url('../img/app/palette-border.png');
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
}
.btn{
	padding: 18px 20px;
	border-radius: 50px;
	font-family: $geneva;
	transition: all 0.2s ease-in;
	outline: none;
	&:focus, &:hover {
		outline: none;
	}
	&.btn-orange {
		max-width: 196.55px; 
		width:100%;
		background: #16638f;
		color: #ffffff;
		&:hover {
			background: white;
			color:#16638f;
			border-color: #16638f;
		}
	}
	&.btn-transparent {
		height:58px;
		background: transparent;
	}
	&.btn-gradient {
		max-width: 196.55px; 
		width: 100%;
		color: #ffffff;
		background: #31a6b5;
		transition: all 0.2s ease-in;
		&:hover {
			background: white;
			color:#31a6b5;
			border-color: #31a6b5;
		}
	}
	&.icon-left {
		i {
			padding-right: 5px;
		}
	}
	&.icon-right {
		i {
			padding-left: 5px;
		}
	}
	&.btn-white-o {
		border: 1px solid #ffffff;
		color: #ffffff;
		&:hover {
			background: #ffffff;
			color: $black;
		}
	}
	&.btn-turquoise {
		border: 1px solid #48a7b4;
		background: #ffffff;
		color: #48a7b4;
		&:hover {
			background: #48a7b4;
			color: #ffffff;
		}
	}
}
.carousel_wrap { 
	height: auto;
	position:relative;
	.nav_button { 
		position:absolute;
		left:0px;
		top:50%;
		margin-top:-30px;
		height:60px;
		width:30px;
		right:0px;
		font-size:40px;
		font-weight:600;
		color:$black;
		cursor:pointer;
		display:inline-block;
		transition: all .3s ease;
	}
	.nav_button.prev_button {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		font-family: FontAwesome;
		font-weight: 300;
		font-size: 30px; 
		left:50px;
		&:after {
			content: "\f053";
		}
	}
	.nav_button.next_button { 
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		font-family: FontAwesome;
		font-weight: 300;
		font-size: 30px;
		left:auto;
		right:50px;
		&:after {
			content: "\f054";
		}
	}
	#carousel {
		width: 100%;
		margin:0px auto;
		margin-top: 50px;
		margin-bottom: 60px;
		height: 700px;
		position:relative;
		clear:both;
		overflow:hidden;
		> div {
			visibility:hidden;
			cursor:pointer;
			> img {
				opacity:0;
				width: 425px;
				height: 670px;
				@media screen and (max-width: 500px) {
					width: 280px;
				}
			}
			.carousel-container {
				position: absolute;
				background-color:#fff;
				box-shadow: -3px 3px 26px 1px rgba(0,0,0,0.1);
				top: 0px;
				bottom: 0px;
				right: 0;
				left: 0px;
				height:100%;
				width:100%;
				box-sizing:border-box;
				display:block;
				text-align: center;
				padding: 20px 40px;
			}
		}
	}
}
.section-title {
	display: flex;
	display: -webkit-flex;
	align-items: flex-end;
	h2{
		font-family: $mwbold;		
		font-size: 40px;
		line-height: 1.2;
		margin:0 auto;
		text-align: center;
	}
	.big {
		font-size: 40px;
		font-family: $worksansBold;
		line-height: 1;
	}
}

.home-page {
		margin:0 auto;
		.container { 
			// position: relative;
			max-width:1366px;
			.row{
				display:flex;
				justify-content: space-between;    
				padding: 80px 0 50px 0;
				.left{					
					width:50%;
					.tag {
						margin-bottom: 21px;
						max-width: 522px;
						width:100%;
						h2{
							font-family: $mwbold;
							font-size:40px;
							line-height: 1.2;
						}
						p{
							font-size:20px;
							line-height: 1.2;
						}
						@media (min-width: 1200px) {
							font-size: 60px;
						}
					}
					.btn {
						margin-bottom: 15px;
					}
					.input-group {
						margin-top: 20px;
						max-width: 300px;
						border-bottom: 1px solid #545454;
						input {
							border: 0;
							box-shadow: none;
							outline: none;
							height: 42px;
							padding-left: 15px;
						}
						i {
							font-size: 18px;
							line-height: 1;
						}
						.btn {
							padding: 7px 12px;
						}
					}
					.video-container {
						display: grid;
						grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
						grid-gap: 20px;
					}
					.video-text {
						align-self: center;
						justify-content: end;
						.small-text {
							font-size: 17px;
							font-family: $worksansSemiBold;
							line-height: 1;
						}
						.big-text {
							font-size: 25px;
							font-family: $worksansBold;
							line-height: 1;
						}
					}
					.video {
						border-radius: 100%;
						max-width: 408.55px;
						background-size: cover!important;
						background-repeat: no-repeat!important;
						background-position: center center!important;
						display: flex;
						display: -webkit-flex;
						align-items: center;
						justify-content: center;
						button {
							background: transparent;
							width: 100%;     
							height: 58px;
							border-radius: 50px;
							border-color: #48a7b4;
							outline: none;
							display: flex;
							display: -webkit-flex;
							align-items: center;
							color:#48a7b4;
							justify-content: center;
							span{
								padding-right:5px;
							}
							&:hover, &:focus {
								outline: none;
								border:none;
								color:white;
								background: #48a7b4;
								i{
									color:white;
								}
							}
							i {
								
								color: #48a7b4;
								line-height: 1;
							}
						}
					}
					.text {
						margin-top: 25px;
						font-size: 16px;
						a {
							font-family: $geneva;
							font-size: 16px;
						}
				}
			}
			.right{
				width:50%;
				img{
					max-width: 562.6px;
					max-height: 403.48px;
					object-fit: cover;
					object-position: center;
					width:100%;
					height: 100%;
				}
			}
		}
}
	.section {
		padding: 50px 0px 0px 0;
		&.why-section {
			h2{
				max-width:403;
				width:100%;
				min-height: 50px;
			}
			.pre-container {
				max-width:1280px;
				padding-right: 15px;
				padding-left: 15px;
				margin-right: auto;
				margin-left: auto;
				
			}
			.row{
				margin: 85px 0;
				display: flex;
				flex-direction: row;
				padding:0;
				.card{
					max-width: 396px;
					width:33.33%;
					// border-radius: 5px;
					box-shadow: 0 0px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
					text-align: center;
					padding:60px 30px 0 30px;
					margin: 10px;
					min-height: 497.61px;

					.logo{
						margin:0 auto;
						padding-bottom:25px;
						img{
							max-width: 57.65px;
							min-height: 71.49px;
							object-fit: contain;
							object-position: center;
						}
						
					}
					h6{
						line-height: 1.5;
						font-family: $mwbold;
						font-size:24px;
					}
					p{
						font-size:20px;
					}
					.caption{
						min-height: 80px;
						margin-bottom:40px;
					}
					.content{
						text-align:left;
						display:flex;
						i{
							color:#31a6b5;
							font-size:24px;
						}
						p{
							padding:0px 10px;	
						}
					}
				}
			}
			.whys {
				margin: 50px 0;
				.circle {
					border-radius: 100%;
					width: 300px;
					height: 300px;
					margin: 0 auto;
					display: flex;
					display: -webkit-flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					transition: all .3s ease;
					.image {
						text-align: center;
						margin-bottom: 20px;
						img {
							margin: 0 auto;
							max-width: 100px;
						}
					}
					.text {
						text-align: center;
						max-width: 75%;
						.title {
							font-family: $worksansBold;
							font-size: 20px;
							margin-bottom: 15px;
						}
						.description {
							font-family: $geneva;
						}
					}
				}
				
				.benefits {
					margin: 50px auto;
					max-width: 400px;
					opacity: 0;
					transition: all .3s ease;
					.benefit {
						display: grid;
						grid-template-columns: 40px 1fr;
						grid-gap: 20px;
						align-items: center;
						margin-bottom: 20px;
						&:last-child {
							margin-bottom: 0;
						}
						.icon {
							display: flex;
							display: -webkit-flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							width: 40px;
							height: 40px;
							border-radius: 100%;
							background: rgba(21,99,142,1);
							background: -moz-linear-gradient(top, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
							background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(21,99,142,1)), color-stop(100%, rgba(106,198,192,1)));
							background: -webkit-linear-gradient(top, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
							background: -o-linear-gradient(top, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
							background: -ms-linear-gradient(top, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
							background: linear-gradient(to bottom, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
							color: #ffffff;
							font-size: 17px;
						}
						.text {
							text-align: left;
							font-size: 16px;
						}
					}
				}
				#carousel {
					.shadow {
						&.carousel-center {
							.circle {
								-webkit-box-shadow: 0px 10px 30px 5px rgba(0,0,0,0.1);
								-moz-box-shadow: 0px 10px 30px 5px rgba(0,0,0,0.1);
								box-shadow: 0px 10px 30px 5px rgba(0,0,0,0.1);
							}
							.benefits {
								opacity: 1;
							}
						}
					}
				}

			}
		}
		&.client-section {
			background-color: #f5f5f5;
			.section-title {
				text-align: center;
				font-family: $worksansBold;
				flex-direction: column;
				align-items: center;
				.small {
					font-size: 16px;
					line-height: 1;
				}
				.big {
					font-size: 40px;
					line-height: 1;
				}
			}
			.clients {
				margin: 50px auto;
				max-width: 700px;
				overflow: hidden;
				.client {
					display: flex;
					max-width: 150px;
					outline: none;
					align-items: center;
					align-content: center;
					height: 130px;
					img {
						max-width: 100px;
					}
				}
			}
			.arrows-down {
				text-align: center;
				button {
					border: none;
					background: transparent;
					outline: none;
					line-height: 1;
					width: 50px;
					text-align: center;
					i {
						font-size: 30px;
						color: #8d8d8d;
					}
				}
			}
		}
		&.app-section {
			padding: 74px 0 0;
			background: rgba(21,99,142,1);
			background: -moz-linear-gradient(top, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(21,99,142,1)), color-stop(100%, rgba(106,198,192,1)));
			background: -webkit-linear-gradient(top, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
			background: -o-linear-gradient(top, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
			background: -ms-linear-gradient(top, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
			background: linear-gradient(to bottom, rgba(21,99,142,1) 0%, rgba(106,198,192,1) 100%);
			color: #ffffff;
			position: relative;
			min-height:593px;
			height:100%;
			h2{
				font-family: $mwbold;
				font-size:40px;
				line-height: 1.2;
				text-align: center;
				margin:0 auto;
				max-width:30%;
			}
			.slick-dots {
				bottom:0;
				position: absolute;        
				width: 100%;
				// padding-bottom: 55px;
				li {
					position: relative;
					display: inline-block;
					width: 30px;
					height: 30px;
					padding: 0 25px;
					margin: 0;
					cursor: pointer;

					button {
						font-size: 0;
						line-height: 0;
						display: block;
						width: 20px;
						height: 20px;
						padding: 5px;
						cursor: pointer;
						color: transparent;
						border: 0;
						outline: none;
						background: transparent;
						&:before {
							content: '•';
							font-size: 45px;
							line-height: 20px;
							position: absolute;
							top: 0;
							left: 0;
							width: 20px;
							height: 20px;
							text-align: center;
							opacity: .60;
							color: white;
						}
					}

					&.slick-active {
						button:before {
							opacity: 1;
						}
					}
				}
			}
			.overlay {
				display: none;
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.4);
				z-index: 2;
			}
			.container {
				position: relative;
			}
			.flex {
				display: flex;
				display: -webkit-flex;
			}
			.slick-slide {
				outline: none!important;
				z-index: 0 !important;
			}
			.text {
				outline: none;
				margin-top: 15px;
				.quote {
					font-family: $geneva;
					margin: 38px auto;
					line-height: 1.5;
					max-width:815px;
					width: 100%;
					p{
						font-size: 30px;
					}
					.bold {
						font-family: $geneva;
					}
				}
				.author {
					margin-top:53px;
					.name {
						font-family: $geneva;
						font-size: 20px;
						line-height: 1;
					}
					.position {
						font-size: 14px;
					}
				}
				
			}
			
			.slider-container {
				max-width: 1140px;
				position: relative;	
				// z-index: 9;
				text-align: center;
				.slider{
					min-height:380px;
				}
			}
		}
		&.download-section{
			.container{
				padding-top: 18px;
				.row{
					max-width: 100%;
					display:flex;
					flex-direction: row;
					padding:0;
					.stores {
						position: relative;	
						display:flex;
						flex-direction: column;
						padding:50px 0;
						h2{
							margin-bottom:0px;
							font-family: $mwbold;
							font-size:40px;
							line-height: 2;
							width:550px;
						}
						p{
							font-size:20px;
							line-height: 1.5;
							width:450px;
							margin-top: 5px;
						}
						.buttons{
							display: flex;
							flex-direction: row;
							margin-top:24px;
							justify-content: space-between;
							max-width: 410px;
							a {
							display: flex;
							display: -webkit-flex;
							margin-bottom: 10px;
							&:hover,&:active{opacity: 0.6;}
							img {
								max-height: 60px;
							}
						}
					}
				}
				.image {
					max-width: 346px;
					width:100%;
					height: 544px;
					background: url(../img/app/mobile.png);
					background-size: 347px;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}
	}
} 

.image-div{
	display:flex;
	justify-content: flex-end;
	width:50%;
}
	
		
	

	@media (max-width: 991px) {
		.app-section {
			.overlay {
				display: block!important;
			}
		}
	}
	@media (max-width: 768px) {
		.hero-banner {
			padding-left: 0;
			.tag {
				font-size: 40px;
			}
			.social {
				top: -50px;
				left: 15px;
				li {
					display: inline-block;
				}
			}
			.btn-orange {
				margin-bottom: 10px;
			}
		}
		.section {
			&.app-section {
				.image {
					background-size: 250px;
					background-position: bottom right;
				}
			}
		}
		
	}
	@media (max-width: 500px) {
		.section {
			&.why-section {
				.whys {
					margin-bottom: 0;
					.benefits {
						max-width: 300px;
					}
					.circle {
						width: 300px;
						height: 300px;
						.image {
							img {
								max-width: 100px;
							}
						}
					}
				}
				.carousel_wrap {
					#carousel {
						margin-bottom: 0;
					}
				}
			}
			&.app-section {
				.overlay {
					display: none!important;
				}
				
				.text {
					margin-top: 0;
				}
				.image {
					background-size: 200px;
    				background-position: bottom right;
				}
				.stores {
					a {
						img {
							max-height: 37px;
						}
					}
				}
				.slick-dots{
				}
			}
			&.download-section{
				transform: translateY(-13%);
				.container{
					.row{
						.stores{
							h2{
								font-size:22px;
								line-height: 1.5;
							}
							

						}
						.image{
							background-size:165px;
						}
					}
				}
			}
		}
		
	}
}
.about-page {
	.about-overlay{
		background: url('../img/app/ss.png');
		position: absolute;
		width: 100%;
		min-height:955px;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.4;
	}
	.about-header{
		height: 375px;
		.container{
			max-width: 1366px;
			padding:0;
			z-index: 2;
			position: relative;
			.title{
				width:100%;
				min-height:375px;
				display:flex;
				padding: 0;
				flex-direction: column;
				color:white;
				// justify-content: center;
				h2{
					margin-top:88px;
					margin-bottom:0px;
					font-family: $mwbold;
					font-size:40px;
					max-width:331px;
					min-height: 50px;
				}
				p{
					margin-top:24px;
					margin-bottom:0px;
					font-size:20px;
					max-width:502px;
					min-height: 62px;
				}
				button{
					margin-bottom:0px;
					margin-top:25px;
					max-width: 196.55px;
					width:100%;
					max-height: 58px;
					background: #30A7B4;
					span{
						font-size:16px;
					}
					&:hover,&:focus{
						color:white;
						border: 1px white solid;
						background: transparent;
					}
				}
			}
		}
	}
	.about-content{
		padding-top:71.33px;
		background: white;	
		z-index: 2;
		position: relative;
		.container{
			.content{
				max-width:1088px;
				margin:0 auto;
				font-size:20px;
				min-height: 950px;
				p{
					margin:0 0 20px;
				}
			}
		}
	}

	.mission-vision{
		background:#FCFCFC;
		padding-top:61.83px;
		padding-bottom:47.5px;
		min-height: 770px;
		.container{
			padding:0;
			display: flex;
			.left{
				max-width:630px;
				width:100%;
				h2{
					font-family: $mwbold;
					font-size:40px;
					min-height: 50px;
				}
				.content{
					margin-top:39.5px;
					padding-left:27.96px;
					font-size:20px;
					max-width:509px;
					p{
						width: 509px;
						margin:0 0 20px 0;
					}

				}
				.mission{
					margin-top:12.5px;
					margin-bottom:56.5px;
					h2{
						max-width:250px;
					}
				}
				.vision{
					h2{
						max-width:216px;
					}
				}
			}
			.right{
				max-width:486px;
				width:100%;
				.row{
					width: 100%;
					margin:0;
					display: flex;
					.image-1{
						min-height:279px;
						width:100%;
						background-size: cover !important;
						background-repeat: no-repeat !important;
					}
					.image-2{
						min-height:212px;
						width:50%;
						background-size: cover !important;
						background-repeat: no-repeat !important;
					}
					.image-3{
						min-height:212px;
						width:50%;
						background-size: cover !important;
						background-repeat: no-repeat !important;
					}
					.image-4{
						min-height:279px;
						width:100%;
						background-size: cover !important;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}

	.competetive-edge{
		background:white;
		.container{
			padding:0;
			width: 1366px;
			min-height: 490.64px;
			.title{
				padding-top:93px;
				padding-bottom:102.97px;
				h2{
					text-align: center;
					min-height: 50px;
					max-width: 446px;
					font-family: $mwbold;
					font-size:40px;
					margin: 0 auto;
				}	
			}
			.row{
				margin:0;
				display:flex;
				justify-content: space-between;
				padding-bottom:111px;
				.step{
					max-height: 133.53px;
					max-width: 328px;
					.header{
						display: flex;
						i{
							font-size:30px;
							color:#31A6B5;
							align-self: center;
						}
						h5{
							font-family: $mwbold;
							font-size:32px;
							opacity:0.25;
							padding:0 13.1px;	
							max-width: 34px;
							min-height: 40px;			
						}
					}
					.content{
						p{
							font-size:20px;
						}
					}
				}
			}
		}
	}
}
.why-page {
	.why-overlay{
		background: url('../img/app/Path 1097.svg');
		position: absolute;
		width: 100%;
		min-height:375px;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.8;
	}
	.why-header{
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		
		.container{
			min-height: 375px;
			max-width: 1366px;
			padding:0;
			z-index: 2;
			position: relative;
			color:white;
			display: flex;
			justify-content: center;
			flex-direction: column;
			h2{
				font-family: $mwbold;
				font-size:40px;
				max-width:383px;
				min-height:50px;
				transform: translateY(-60%);
				margin:0;
			}
			p{
				font-size:20px;    
				transform: translateY(-10%);
				max-width: 502px;
				min-height: 94px;
			}
		}	
	}
	.why-video{
		padding-top:67.33px;
		.title{
			h2{
				text-align: center;
				min-height: 50px;
				max-width: 683px;
				font-family: $mwbold;
				font-size:40px;
				margin: 0 auto;
			}
		}
		.video-parent{
			max-width:1234px;
			display:flex;
			margin:0 auto;
			justify-content: space-between;
			padding-top:72px;
			.video-container{
				max-width:606px;
				.video-player{
					position:relative;
					video{
						max-width:606px;
						max-height: 346px;
						filter: brightness(75%);
					}
					button{
						top:40%;
						left:40%;
						background: transparent;
						border:0;
						outline:0;
						position: absolute;
						transition: all 0.3s ease-in;
						i{
							font-size:81px;
							color:white;
						}
					}
					.hide{
						display: none;
					}
				}
				.video-text{
					padding-top:31.9px;
					h4{
						font-family: $mwbold;
						font-size: 24px;
						max-width:432px;
						min-height: 31px;
						padding-bottom:16.1px
					}
					p{
						font-size:20px;
						max-width: 508.19px;
						min-height: 97px;
					}
				}
			}
		}
	}
	.why-cards{
		background:#FCFCFC;
		
		.header{
			.title{
				padding-top:100px;
				padding-bottom:58.9px;
				h2{
					text-align: center;
					min-height: 50px;
					max-width: 446px;
					font-family: $mwbold;
					font-size:40px;
					margin: 0 auto;
				}	
			}
		}
		h2{
			max-width:403;
			width:100%;
			min-height: 50px;
		}
		.pre-container {
			max-width:1235px;
			padding-right: 15px;
			padding-left: 15px;
			margin-right: auto;
			margin-left: auto;
			
		}
		.row{
			padding: 0 0 90.03px 0;
			display: flex;
			flex-direction: row;
			.card{
				max-width: 396px;
				width:33.33%;
				box-shadow: 0 0px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
				text-align: center;
				padding:60px 30px 0 30px;
				margin: 0 8px;
				min-height: 497.61px;
				.logo{
					margin:0 auto;
					padding-bottom:25px;
					img{
						max-width: 57.65px;
						min-height: 71.49px;
						object-fit: contain;
						object-position: center;
					}
					
				}
				h6{
					line-height: 1.5;
					font-family: $mwbold;
					font-size:24px;
				}
				p{
					font-size:20px;
				}
				.caption{
					min-height: 80px;
					margin-bottom:40px;
				}
				.content{
					text-align:left;
					display:flex;
					i{
						color:#31a6b5;
						font-size:24px;
					}
					p{
						padding:0px 10px;	
					}
				}
			}
		}
	}
	.why-loans{
		.title{
			padding-top:69.46px;
			max-width:565px;
			min-height: 50px;
			margin:0 auto;
			text-align: center;
			padding-bottom:90px;
			h2{
				font-family: $mwbold;
				font-size:40px;
				min-height: 50px;
			}
		}
		.pre-container{
			max-width:1265px;
			padding-right: 15px;
			padding-left: 15px;
			margin-right: auto;
			margin-left: auto;
			padding-bottom:89.78px;
			.row{
				display: flex;
				justify-content: space-between;
				flex-direction: row;
				padding-top:24px;
				.card{			
					margin: 0 11px;
					box-shadow:0 0px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
					max-width: 606px;
					min-height: 346px;
					width:100%;
					background: #fff;
					.logo{
						padding-left:49px;
						padding-top:62px;
						img{
							max-width: 71.49px;
							max-height: 71.49px;
							object-fit: contain;
							object-position: center;
						}
					}
					.text{
						padding-left:49px;	
						padding-right:49px;	
						padding-top:45.41px;				
						.card-title{
							font-family: $mwbold;
							font-size:24px;
							padding-bottom:21.1px;
						}
						.content{
							font-size:20px;
							min-height: 97px;
						}
					}
				}
			}
		}

	}
	.why-contact{
		background:#30A7B4;
		.container{
			display:flex;
			flex-direction: row;
			max-width: none;
			min-height: 298.4px;
			padding: 0;
			z-index:none;
			.header{
				width:50%;
				display:flex;
				justify-content: center;
				flex-direction: column;
				color:white;
				h2{
					font-family: $mwbold;
					font-size:40px;
					max-width: 534px;    
					transform: translateY(-35%);
					min-height: 50px;
				}
				p{
					max-width: 508.19px;
					font-size:20px;
					min-height: 56px;
				}
			}
			.content{
				width:45%;
				display:flex;
				justify-content: center;
				align-items: flex-end;
				flex-direction: column;
				a{
					color:#30A7B4;
					background:white;
					max-width:236px;
					width:100%;
					min-height: 58px;
					font-size:16px;
					&:hover,&:active{
						border:1px white solid;
						background:#30A7B4;
						color:white;
					}
				}
			}
		}
	}
}

.confirm{
	border-radius:50px !important;

}
.sweet-alert{
	&.customClass{
		width:807px;
		height:382px;
		margin-left: -403.5px;
	}
	&.customWarning{
		h2{display:none;}
	}
	h2{
		font-family: $mwbold;
		font-size:40px;
		height:50px;
		max-width:577px;
		margin:0 auto;
		margin-top:95px;
		color:#1A1A1A;
	}
	p{
		font-family:$geneva;
		font-size:20px;
		max-width: 600px;
		height:36px;
		margin:0 auto;
		margin-top:24px;
	}
	button{
		max-width: 196.55px;
		height: 58px;
		margin-top:24px;
		font-size:16px;
		width:100%;
		font-family: $geneva;
	}
}
	

.grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}
.news-page {
	.news-overlay{
		background: url('../img/app/Path 1097.svg');
		position: absolute;
		width: 100%;
		min-height:375px;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.8;
	}
	.news-header{
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		.container{
			min-height: 375px;
			max-width: 1366px;
			padding:0;
			z-index: 2;
			position: relative;
			color:white;
			display: flex;
			justify-content: center;
			flex-direction: column;
			h2{
				font-family: $mwbold;
				font-size:40px;
				max-width:268px;
				min-height:50px;
				transform: translateY(-80%);
				margin:0;
			}
			p{
				font-size:20px;    
				transform: translateY(-35%);
				max-width: 502px;
				min-height: 57px;
			}
		}
	}
	.featured-news{
		background:#FCFCFC;
		.pre-container{
			padding: 75.23px 0 15.07px 0;
			max-width:1235px;
			padding-right: 15px;
			padding-left: 15px;
			margin-right: auto;
			margin-left: auto;
			.row{
				display: flex;
				flex-direction: row;
				.card{
					max-width: 396px;
					width:100%;
					box-shadow: 0 0px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
					margin: 0 7.75px;
					margin-bottom:32.49px;
					min-height: 642.61px;
					.picture{
						background-size: cover !important;
						background-repeat: no-repeat !important;
						background-position: center !important;
						width:100%;
						max-height: 241.61px;
						height: 100%;
					}
					.content{
						padding:27.49px 30px 0 30px;					
						.date{
						opacity:0.5;
						font-size:20px;
						max-width:328px;
						min-height: 25px;
						}
						.title{
							font-family: $mwbold;
							max-width: 335px;
							h5{
								font-size:24px;
								line-height: 1.5;
							}
						}
						.blurb{
							margin-bottom:40px;
							overflow:hidden;
							max-height: 83px;
							height: 100%;
							max-width: 328px;
							p{
								font-size:20px;
							}
						}
						.read-more{
							a{
								max-width: 335px;								
								font-size:20px;
								color:#30A7B4;
								i{
									font-size:20px;
									color:#30A7B4;
								}	
								&:hover{
									opacity: 0.5;
								}
							}
						}
					}
				}
			}
		}
		.load-more{
			display: flex;
			justify-content: center;
			padding-bottom:58.78px;
			button{
				outline: none;
				max-width: 396px;
				width:100%;
				max-height: 58px;
				font-size:16px;
				color:white;
				background: #31A6B5;
				&:hover,&:active{
					color:#31A6B5;
					background: transparent;
					border:1px #31A6B5 solid;
				}
			}
		}
	}
	
	.news-letter{
		min-height: 298.4px;
		background:#31A6B5;
		.container{
			display:flex;
			flex-direction: row;
			max-width: none;
			min-height: 298.4px;
			padding: 0;
			z-index:none;
			.header{
				width:50%;
				display:flex;
				justify-content: center;
				flex-direction: column;
				color:white;
				h2{
					font-family: $mwbold;
					font-size:40px;
					max-width: 566px;    
					transform: translateY(-30%);
					min-height: 50px;
				}
				p{
					max-width: 508.19px;
					font-size:20px;
					min-height: 56px;
				}
			}
			.content{
				width:50%;
				display:flex;
				justify-content: center;
				align-items: flex-end;
				flex-direction: column;
				input{
					max-width:397.63px;
					min-height: 58px;
					text-indent: 23.16px;
					font-size:16px;
					border-radius: 50px;
					border: 0;
					width:100%;
				}
			}
		}
	}
}
.article-page { 
	background-color: #fcfcfc;
	padding-top:52px;
	.container{
		padding:0;
		a{
			font-size: 20px;
			color:#30A7B4;
			i{
				color:#30A7B4;
				padding-right: 16px;
				font-size: 20px;
			}
		}
		h2{
			font-family: $mwbold;
			font-size:40px;
			max-width: 671px;
			min-height: 100px;
			margin-top:55px;
		}
		h5{
			font-size:20px;
			opacity:0.5;
			margin:16px 0 55px 0;
			max-width: 328px;
			min-height: 25px;
		}
	}
	.article-image{
		min-height: 406px;
		background-repeat: no-repeat !important;
		background-position: center !important;
		background-size: cover !important;
	}
	.article-body{
		margin-top: 55PX;
		strong{
			font-family: $mwbold;
		}
		.container{
			padding-right: 15px;
			padding-left: 15px;
			img{
				max-width:1092px;
				width:100% !important;
				object-fit: contain;
				object-position: center;
				min-height: 583px;
			}
			p{
				font-size:20px;
				margin-bottom:20px;
				max-width:1092px;
			}
		}
	}
	.social-media{
		hr{
			max-width: 940px;
			margin-top:55px;
		}
		.caption{
			margin-top:55px;
			h5{
				font-size:20px;
				margin:0 auto;
				min-height: 25px;
				max-width: 621px;
			}	
		}
		.logo{
			width:100%;
			max-width:111.22px;
			margin:0 auto;
			display:flex;
			margin-top:50px;
			margin-bottom: 57px;
			justify-content: space-between;
		}
	}
	.other-news{
		background:white;
		.container{
			min-height: 50px;
			padding-top:10px;
			h2{
				min-height: 50px;	
			}
		}
		.pre-container{
			padding: 48.23px 0 15.07px 0;
			max-width:1235px;
			padding-right: 15px;
			padding-left: 15px;
			margin-right: auto;
			margin-left: auto;
			.row{
				display: flex;
				flex-direction: row;
				.card{
					max-width: 396px;
					width:100%;
					box-shadow: 0 0px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
					margin: 0 7.75px;
					margin-bottom:32.49px;
					min-height: 642.61px;
					.picture{
						background-size: cover !important;
						background-repeat: no-repeat !important;
						background-position: center !important;
						width:100%;
						max-height: 241.61px;
						height: 100%;
					}
					.content{
						padding:27.49px 30px 0 30px;					
						.date{
						opacity:0.5;
						font-size:20px;
						}
						.title{
							font-family: $mwbold;
							h5{
								font-size:24px;
								line-height: 1.5;
							}
						}
						.blurb{
							margin-bottom:40px;
							overflow:hidden;
							max-height: 83px;
							height: 100%;
							p{
								font-size:20px;
							}
						}
						.read-more{
							a{
								font-size:20px;
								color:#30A7B4;
								i{
									font-size:20px;
									color:#30A7B4;
								}	
								&:hover{
									opacity: 0.5;
								}
							}
						}
					}
				}
			}
		}
	}
}
.careers-page {
	.container{
		.header{
			text-align: center;
			padding: 50px 0;
			border-bottom: 1px solid #d4d4d4;
			.title{
				font-family:$mwbold;
				font-size:40px;
				margin: 0;
			}
			h4{
				font-size:20px;
				a:hover,a:active{
					color:#30A7B4;
				}
			}
		}
		.jobs{
			padding: 50px 0;
			grid-gap: 40px;
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
			max-width: 80%;
			margin: 0 auto;
			.job{
				.position{
					font-family: $mwbold;
					font-size:18px;
				}
				.description{
					font-size:16px;
				}
				.date{
					font-size: 13px;
    				 color: #d4d4d4;
				}
			}
		}
		.soon{
			padding-bottom:375px;
			padding-top:187px;
			h2{
				margin-left:auto;
				margin-right:auto;
				font-size: 40px;
				font-family: $mwbold;
				color:#30A7B4;
				max-width:704px;
				height: 50px;
			}
			p{
				padding-top: 24px;
				font-size: 20px;
				max-width:696px;
				min-height: 52px;
				margin-left:auto;
				margin-right:auto;
				text-align: center;
				a{
					color:#30A7B4;
					&:hover,&:focus,&:active{
						opacity: 0.5;
					}
				}
			}
		}
	}
}
.contact-page {
	.contact-overlay{
		background: url('../img/app/Path 1097.svg');
		position: absolute;
		width: 100%;
		min-height:375px;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.8;
	}
	.contact-header{
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		.container{
			min-height: 375px;
			max-width: 1366px;
			padding:0;
			z-index: 2;
			position: relative;
			color:white;
			display: flex;
			justify-content: center;
			flex-direction: column;
			h2{
				font-family: $mwbold;
				font-size:40px;
				max-width:268px;
				min-height:50px;
				transform: translateY(-60%);
				margin:0;
			}
			p{
				font-size:20px;    
				transform: translateY(-20%);
				max-width: 502px;
				min-height: 57px;
			}
		}
	}
	.contact-body{
		.pre-container{
			padding:0;
			display: flex;
			max-width:1170px;
			justify-content: space-between;
			width:100%;
			padding-right: 15px;
			padding-left: 15px;
			margin-right: auto;
			margin-left: auto;
			.right{
				padding-top:49.33px;
				max-width:730.55px;
				width:100%;
				h6{
					font-size:20px;
				}
				form{
					padding-top:33.21px;
					.row{
						display: flex;
						flex-direction: row;
						margin:0;
						width:100%;
						.input-grp{
							width:100%;
							max-width: 353.85px;
							min-height: 81.63px;
							display: flex;
							flex-direction: column;
							margin-bottom:16.37px;
							&:first-child{
								margin-right:23.15px;
							}	
							input{
								border:1px #E7E7E7 solid;
								height:52px;
								border-radius: 50px;
								font-size:16px;
								text-indent: 23.7px;
							}
							label{
								font-size:16px;
								margin-bottom:10px;
							}
						}
					}
					.check-grp{
						padding-bottom:54px;
						display:flex;
						.checkbox {
							width: 100%;
							margin: 15px auto;
							position: relative;
							display: block;
						}
						
						.checkbox input[type="checkbox"] {
							width: auto;
							opacity: 0.00000001;
							position: absolute;
							left: 0;
							margin-left: -20px;
						}
						.checkbox label {
							position: relative;
						}
						.checkbox label:before {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							margin: 4px;
							width: 22px;
							height: 22px;
							transition: transform 0.28s ease;
							border-radius: 3px;
							border: 1px solid #E7E7E7;
							width: 30px;
							height: 30px;
						}
						.checkbox label:after {
							content: '';
							display: block;
							width: 15px;
							height: 10px;
							border-bottom: 4px solid #ffffff;
							border-left: 4px solid #ffffff;
							-webkit-transform: rotate(-45deg) scale(0);
							-moz-transform: rotate(-45deg) scale(0);
							-ms-transform: rotate(-45deg) scale(0);
							transform: rotate(-45deg) scale(0);
							position: absolute;
							top: 12px;
							left: 12px;	
						}
						.checked{
							&:before{
								background: #30a7b4;
								content: '';
								position: absolute;
								left: 0;
								top: 0;
								margin: 4px;
								width: 30px;
								height: 30px;
								transition: transform 0.28s ease;
								border-radius: 3px;
								border: 1px solid #E7E7E7;	
							}
						}
						.checkbox input[type="checkbox"]:checked ~ label::before {
							color: #7bbe72;
						}
						
						.checkbox input[type="checkbox"]:checked ~ label::after {
							-webkit-transform: rotate(-45deg) scale(1);
							-moz-transform: rotate(-45deg) scale(1);
							-ms-transform: rotate(-45deg) scale(1);
							transform: rotate(-45deg) scale(1);
						}
						
						.checkbox label {
							display: block;
							padding-left: 40px;
							margin-bottom: 0;
							cursor: pointer;
							vertical-align: sub;
							min-height: 41px;
							display: block;
							padding-left: 40px;
							margin-bottom: 0;
							font-weight: normal;
							cursor: pointer;
							vertical-align: sub;
							font-size:16px;
							max-width:424px;
							a{
								color:#16638F;
							}
						}
						.checkbox label span {
							position: absolute;
							top: 50%;
							-webkit-transform: translateY(-50%);
							transform: translateY(-50%);
						}
						.checkbox input[type="checkbox"]:focus + label::before {
							outline: 0;
						}
						.check{
							width: 100%;
							margin: 15px auto;
							position: relative;
							display: block;
							p{
								cursor:text;
								// position: relative;
								min-height: 41px;
								display: block;
								padding-left: 40px;
								margin-bottom: 0;
								font-weight: normal;
								cursor: pointer;
								vertical-align: sub;
								font-size:16px;
								max-width:424px;
								// min-height: 41px;
								// margin-right:71.45px;
								a{
									color:#16638F;
								}
								&:before{
									content: '';
									position: absolute;
									left: 0;
									top: 0;
									margin: 4px;
									width: 22px;
									height: 22px;
									transition: transform 0.28s ease;
									border-radius: 3px;
									border: 2px solid grey;
								}
								&:after{
									content: '';
									// display: block;
									width: 10px;
									height: 5px;
									border-bottom: 2px solid grey;
									border-left: 2px solid grey;
									-webkit-transform: rotate(-45deg) scale(0);
									-moz-transform: rotate(-45deg) scale(0);
									-ms-transform: rotate(-45deg) scale(0);
									transform: rotate(-45deg) scale(0);
									position: absolute;
									top: 12px;
									left: 10px;
								}
							}
						}
						input{
							background:#30A7B4;
							color:white;
							max-width: 196.55px;
							width:100%;
							max-height: 58px;
						}
					}
					.input-grp{
						width:100%;
						max-width: 730.55px;
						min-height: 81.63px;
						display: flex;
						flex-direction: column;
						margin-bottom:16.37px;
						&:first-child{
							margin-right:23.15px;
						}	
						input{
							border:1px #E7E7E7 solid !important;
							height:52px !important;
							outline: none;
							border-radius: 50px;
							text-indent: 23.7px;
						}
						textarea{
							border:1px #E7E7E7 solid !important;
							height:219px !important;
							border-radius: 15px;
							text-indent: 23.7px;
							outline: none;
							padding-top:23.36px;
						}
						label{
							font-size:16px;
							margin-bottom:10px;
						}
					}
				}
			}
			.left{
				padding-top:54.33px;
				// margin-left:100px;
				width:100%;
				max-width: 342px;
				.row{
					display:flex;
					flex-direction: column;
					margin:0;
				}
				div{
					margin-bottom:40px;
				}
				.social{
					a{
						font-size:20px;
						color:#16638F;
						margin-right:16px;						
					}
				}
				h3{
					font-family: $mwbold;
					font-size:24px;
				}
				p{
					margin-top:6px;
					font-size:20px;
				}
				a{
					font-size:20px;
					&:hover{
						opacity:0.5;
					}
				}
			}
		}
	}
}
.promos-page {
	padding: 50px 0;
}

.faqs-page {
	min-height: 100vh;
	padding: 50px 0 100px;
	background-repeat: no-repeat;
	background-position: right top;
	background-size: 30%;
	.banner {
		.banner-content {
			min-height: 40vh;
			max-width: 500px;
			display: flex;
			display: -webkit-flex;
			justify-content: center;
			flex-direction: column;
			.content {
				margin-top: 30px;
				font-size: 16px;
			}
		}
		.title {
			font-size: 40px;
			font-family: $worksansBold;
			line-height: 1.2;
		}
	}
	.collapseContainer {
		margin-top: 50px;
		.grid {
			grid-template-columns: 100px 1fr;
			.header {
				font-size: 20px;
				font-family: $worksansBold;
				color: #48a7b4;
			}
			.accordion {
				max-width: 600px;
				.card {
					border-top: 1px solid #a0a0a0;
					&:last-child {
						border-bottom: 1px solid #a0a0a0;
					}
					.card-header {
						h5 {
							&:focus {
								button {
									outline: none;
								}
							}
							button {
								color: #a0a0a0;
								&:hover, &:focus {
									outline: none;
									text-decoration: none;
									color: $black;
								}
							}
							i {
								color: #48a7b4;
								padding-right: 15px;
							}
						}
					}
					.card-body {
						padding: 0 55px 15px;
					}
				}
			}
		}
	}
	
}
.miscellaneous-page {
	.container{
	padding-top:48px;	
	    a{
			font-size: 20px;
			color:#30A7B4;
			i{
				color:#30A7B4;
				padding-right: 16px;
				font-size: 20px;
			}
		}
		.content{
			padding-top:55px;
			h2{
				font-size:40px;
				font-family: $mwbold;
				max-width: 730px;
				min-height: 56px;
			}
			.body{
				max-width:1092px;
				padding-top:55px;
				margin-left:auto;
				margin-right:auto;
				margin-bottom:134px;
                p{
					font-size:20px;
					margin-bottom:25px;
				}
				strong{
					margin-bottom:20px;
					font-size:20px;
					font-family: $mwbold;
					color:#30A7B4;
				}
			}
		}
	}
}

.pdf-modal{
	height:0;
}

.video-modal{
	padding-right:0 !important;
	.video-dialog{
		width:100%;
		margin:0 !important;
		display:flex !important;
		justify-content: center;
		align-items:center;
		height: 100%;
		.modal-content{
			max-height: 487px;
			max-width: 878px;
			width: 100%;
		}
	}
}




.modal-content {
	box-shadow: none;
	background: transparent;
	border: none;
	height: 100%;
	.headers2{
		position: absolute;
		width: 100%;
		min-height: 54px;
		display:flex;
		justify-content: flex-end;
		align-items: flex-end;
		z-index: 2;
		.cover{
			width: 45px;
			height: 40px;
			background:transparent;
			outline:none;
			border:0;
			i{
				font-size:20px;
				color:white;
			}
		}
	}
	.headers{
		position: absolute;
		width: 98.5%;
		min-height: 54px;
		display:flex;
		justify-content: flex-end;
		align-items: flex-end;
		.cover{
			width: 45px;
			height: 40px;
			background:#30A7B4;
			outline:none;
			border:0;
			i{
				font-size:30px;
				color:white;
			}
		}
	}
	video{
		max-width:878px;
		width:100%;
		max-height:487px;
	}
	// @media(max-width: 479px) {
	// 	transform: translate(0,100%); 
	// }
}

.ndfHFb-c4YZDc-Wrql6b-AeOLfc-b0t70b{
	display:none !important;
}
.ndfHFb-c4YZDc-Wrql6b{
	display:none !important;
}
.white-overlay {
	background: rgba(255,255,255,0.5);
	position: absolute;
	width: 100%;
	height: 100%;
}

.parsley-errors-list {
	li {
		margin-top: 5px;
		font-size: 12px;
		color: red;
	}
}

.image-mobile {
	width: 100%;
    height: 300px;
    background: url(../img/app/mobile.png);
    background-size: 200px;
    background-repeat: no-repeat;
	background-position: 90% 0;
	display: none;
}
.collapse.in{
	display: block !important;
	transform: translateX(0) !important;
	height: 100% !important;
}

.sidebar{
	height: 100%;
    width: 300px;
	position: fixed!important;
	background: white;
    z-index: 500;
	overflow: auto;
	transition: 0.3s;
    transform: translateX(100%);
	top:0;    
	right:0;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	.close{
		margin:20px 0;
		button{
			display:block !important;
			visibility: visible !important;
		}
	}
	.sidenav{
		margin:80px 0;
		font-size: 16px;
		li{
			padding:10px 30px;
			border-left: 3px #ffffff solid;
			&:hover{
				
				border-left: 3px #ffb000 solid;
				cursor: pointer;
				&:last-child{
					border-left: 3px #fff solid ;
					padding:10px 30px;
				}
			}
			
		}
		.dropdown{
			ul{
				margin-top:10px;
				li{
					&:hover{
						padding-left: 63.1px;
						border-left: 3px #ffb000 solid;
						cursor: pointer;
						transform: translateX(-14%);
					}
				}
			}
		}
	}
	.left{
		padding:0 30px;
		font-size:20px;
		color:#16638F;
		.social{
			display: flex;
			li{
				padding:10px 5px;
				a:hover{
					opacity: 0.6;
				}
			}
		}
	}
}

@media (max-width:1275px){
	.cookie-div{
		.container{
			width:80vw;
		}
	}	
	.about-page{
		.competetive-edge{
			.container{
						width:100vw;
			}
		}
	}

	.why-page{
		.why-loans{
			.pre-container{
				.row{
					.card{
						margin: 0px 20px;
						padding-bottom:20px;
					}
				}
			}
		}
		.why-video{
			.video-parent{
				max-width: 1024px;
				padding:72px 15px 0 15px;
				.video-container{
					max-width:495px;
					padding:0 5px;
					.video-player{
						video{
							width:100%;
						}
					}
				}
			}
		}
	}
	
}

@media (max-width:1199px){
	// .container{
		.navbar-default{
			.navbar-toggle{
				display:block;
			}
			.navbody{
				min-height: 80px;
				.navbar-header{
					width:100%;
					display:flex;
					justify-content: space-between;
					flex-direction: row-reverse;
					.navbar-brand{
						width:20%;
						display: flex;
						justify-content: flex-start;
					}
					button{
						width:80%;
						display:flex;
						flex-direction: column;
						align-items: flex-end;
						justify-content: center;
						&:hover{
							span{
								color:grey;
							}
						}
					}
				}
				.left{
					display:none;
				}
			}
		}
	// }
	.navbar-collapse{
		&.collapse{
			display:none !important;
		}
	}

	.home-page{
		.section{
			&.app-section{
				h2{
					max-width:50%;
				}
			}
		}
	}

	.about-page{
		.mission-vision{
			.container{
				.left{
					.content{
						max-width: 440px;
						p{
							max-width: none;
							width:100%;
						}
					}
				}
			}
		}
	}

}

@media(max-width:1094px){
	.home-page{
		.section{
			&.why-section{
				.pre-container{
					.row{
						.card{
							h6{
								font-size:22px;
							}
							p{
								font-size:18px;
							}
						}
					}
				}
			}
		}
	}
}

@media(max-width:1040px){
	.home-page{
		.container{
			.row{
				padding:50px 30px;
			}
		}
	}
}

@media(max-width:1024px){
	footer{
		.container{
			width:100%;
			padding:0 15px;
		}
	}
	.news-page{
		.featured-news{
			.pre-container{
				.row{
					.card{
						.content{
							.date{
								font-size:15px;
							}
							.title{
								h5{
									font-size:20px;
								}
							}
							.blurb{
								p{
									font-size:15px;
								}
							}
							.read-more{
								a{
									font-size:15px;
								}
							}
						}
					}
				}
			}
		}
	}
	.article-page{
		.other-news{
			.pre-container{
				.row{
					.card{
						.content{
							.date{
								font-size:15px;
							}
							.title{
								h5{
									font-size:20px;
								}
							}
							.blurb{
								p{
									font-size:15px;
								}
							}
							.read-more{
								a{
									font-size:15px;
								}
							}
						}
					}
				}
			}
		}
	}
	.about-page{
		.competetive-edge{
			.container{
				.row{
					flex-direction: column;
					align-items: center;
					.step{
						min-height: 133.53px;
						max-height: none;
						padding-bottom:30px;
					}
				}
			}
		}
	}
	.cookie-div{
		height: auto;
		.container{
			.content{
				.body{
					text-align: center;
					font-size:15px;
					div{
						flex-direction: column;
						p{
							text-align: center;
							width:100%;
							padding:0 20px;
						}
						button{
							width:50%;
							margin:0 auto;
							
						}
					}
				}
			}
		}
	}
	.btn{
		&.btn-gradient{
			max-width: 200px;
		}
	}
	.home-page{
		.container{
			.row{
				flex-direction: column;
				padding:50px 0;
				p{
					margin-bottom:30px;
				}
				.right{
					width:100%;
					padding:50px 0;
					text-align: center;
					img{
						max-width: 600px;
					}
				}
				.left{
					width:100%;
					padding:0 20px;
					text-align: center;
					.tag{
						margin: 0 auto;
					}
					.video{
						width:100%;
						margin:0 auto;
						button{
							margin:0 auto;
							max-width:410px;
						}
					}
				}
			}
		}
		.section{
			&.app-section{
				.slider-container{
					.slider{
						min-height: 412px;
					}
				}
			}
			&.why-section{
				.pre-container{
					width:100% !important;
					.row{
						.card{
							h6{
								font-size:22px;
							}
							p{
								font-size:18px;
							}
						}
					}
				}
			}
			&.download-section{
				
				.container{
					margin:0 5%;
					.row{
						justify-content: flex-start;
						.image{
							position: absolute;
							z-index: -1;
							background-size: 250px;
							width: 90% !important;
							height: 515px;
						}
						.stores{
							h2{
								font-size:30px;
							}
							p{
								font-size:15px;
							}
							.buttons{
								max-width: 170px;
								flex-direction: column;
								a{
									img{
										max-width: 216.84px;
										width: 100%;
										max-height: 63.4px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width:1000px){
	.about-page{
		.mission-vision{
			.container{
				.right{
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				.left{
					.content{
						max-width: 320px;
					}
				}
			}
		}
	}
	.contact-page{
		.contact-body{
			.pre-container{
				.right{
					form{
						.check-grp{
							flex-direction: column;
						}	
					}
				}
			}
		}
	}
}
@media (min-width: 992px){
		
	.modal-lg {
		// max-width: 878px;
		// width:100%;
		// max-height:487px;
		// height: 100%;
	}
}

@media(max-width:960px){
	.home-page{
		.section{
			&.why-section{
				.pre-container{
					.row{
						flex-direction: column;   
						margin: 30px 0;
						.card{
							margin: 20px auto;
							max-width: 500px;
							width:45%
						}
					}
				}
			}
		}
	}
	
	.why-page{
		.why-cards{
			.row{
				flex-direction: column;   
				margin: 0;
				padding:25.23px 0 0.03px 0;
				.card{
					margin: 20px auto;
					max-width: 500px;
					width:45%
				}
			}
		}
		.why-loans{
			.title{
				padding-top: 40.46px;
			}
			.pre-container{
				padding-bottom: 32.78px;
			}
		}
	}
}

@media(max-width:850px){
	.sweet-alert{
		&.customClass{
			width: 650px;
			margin-left:-325px;
			
		}
	}
}

@media (max-width:800px){
	.contact-page{
		.contact-header{
			.container{
				align-items: center;
				text-align: center;
			}
		}
		.contact-body{
			.pre-container{
				.right{
					form{
						.row{
							flex-direction: column;
							.input-grp{
								max-width: 730.55px;
							}
						}
					}
				}
			}
		}
	}
	.news-page{
		.news-header{
			.container{
				display:flex;
				align-items: center;
				padding:0 10px;
				text-align: center;
			}
		}
		.featured-news{
			.pre-container{
				.row{
					flex-direction: column;
					align-items: center;
					.card{
						// margin:0 aut
						.picture{
							min-height: 241.61px;
						}
						.content{
							.date{
								font-size:20px;
							}
							.title{
								h5{
									font-size:24px;
								}
							}
							.blurb{
								p{
									font-size:20px;
								}
							}
							.read-more{
								a{
									font-size:20px;
								}
							}
						}
					}
				}
			}
		}
		.news-letter{
			.container{
				flex-direction: column;
				justify-content: space-between;
				.header{
					width: 100%;
					text-align: center;
					padding: 0 20px;
					h2{
						transform: none;
						margin: 20px auto;
					}
					p{
						margin: 0 auto;
					}
				}
				.content{
					width: 100%;
					align-items: center;
				}
			}
		}
	}
	.article-page{
		.container{
			a{
				padding:0 25px;
			}
			h2,h5{
				text-align: center;
				max-width: none !important;	
			}
		}
		.social-media{
			.caption{
				h5{
					text-align: center;
				}
			}
		}
		.other-news{
			.container{
				text-align: center;
				h2{
					max-width: none !important;	
				}
			}
			.pre-container{
				.row{
					flex-direction: column;
					align-items: center;
					.card{
						// margin:0 aut
						.picture{
							min-height: 241.61px;
						}
						.content{
							.date{
								font-size:20px;
							}
							.title{
								h5{
									font-size:24px;
								}
							}
							.blurb{
								p{
									font-size:20px;
								}
							}
							.read-more{
								a{
									font-size:20px;
								}
							}
						}
					}
				}
			}
		}
	}
	.why-page{
		.why-header{
			.container{
				display:flex;
				align-items: center;
				padding:0 10px;
				text-align: center;
			}
		}
		.why-contact{
			.container{
				flex-direction: column;
				justify-content: space-between;
				.header{
					width:100%;
					text-align: center;
					padding:0 20px;
					h2{
						transform: none;
						margin:20px auto;
					}
					p{
						margin:0 auto;
					}
				}
				.content{
					width:100%;
					align-items: center;
				}
			}
		}
	}
	.about-page{
		.about-header{
			.container{
				.title{
					text-align: center;
					align-items: center;
				}
			}
		}
		.mission-vision{
			.container{
				flex-direction: column;
				align-items: center;
				.left{
					order:2;
					padding:30px 15px;	
					h2{
						font-size:38px;
					}
					.content{
						text-align: left;
						font-size:18px;
						max-width: none;
						margin-top:0;
					}
				}
				.right{
					order:1;
				}
			}
		}
	}
}

@media(max-width:768px){
	.modal-dialog{
		padding:0 20px;
	}

	.careers-page{
		.container{
			.soon{
				h2{
					min-height: 50px;
					text-align: center;
					height:unset;
				}
			}
		}
	}
	.contact-page{
		.contact-body{
			.pre-container{
				display:flex;
				flex-direction:column-reverse;
				align-items: center;
				.left{
					max-width: unset;
					.row{
						flex-direction: row;
						div{
							width:50%;
							text-align: center;
						}
					}
				}
			}
		}
	}
	.about-page{
		.about-header{
			.container{
				.title{
					button{
						margin-top:50px;
					}
				}
			}
		}
		.about-content{
			padding-top:40.33px;
			.container{
				.content{
					font-size:18px;
				}
			}
		}
	}
	.why-page{
		.why-header{
			.container{
				p{
					font-size:18px;
				}
			}
		}
		.why-video{
			.video-parent{
				flex-direction: column;
				align-items: center;
				.video-container{
					.video-player{
						video{
							padding:0;
						}
					}
				}
			}
		}
	}
	footer{
		.container,.content{
			flex-direction: column;
			.combine{
				order:2;
				width:100%;
				flex-direction: column;
				align-items: center;
				.menu{
					width:100%;
					order:1;
					padding: 20px 0%;
					max-width: 350px;
					justify-content: space-between;
					div{
						padding-left: 0;
						padding: 0px 5px;
						li{
							padding:0;
						}
					}
					.login-menu{
						display:flex;
						flex-direction: column;
					}
				}

				.button-grp{
					order:2;						
					width: 100%;
					padding: 20px 10%;
				}
			}
			.about{
				order:1;
				padding:20px 0;
				width: 100%;
				align-items: center;
				order:2;
				.address{
				}
				img{
				}
				.contact{
					justify-content: center;
					max-width:350px;
					width: 100%;
				}
				.social{
					width: 100%;
				}
			}
		}
	}

	.navbar-default{
		.navbody{
			.navbar-header{
				
			}
			.left{
				flex-direction: column;
				align-items: flex-end;
				// .social{
				// 	width:35%;
				// }
			}
		}
	}

	.why-page{
		.why-cards{
			.row{
				.card{
					width:55%
				}
			}
		}
	}

	.home-page{
		.section{
			&.why-section{
				.pre-container{
					.row{
						.card{
							width:55%
						}
					}
				}
			}
			&.download-section{
				// padding: 
				.container{
					.row{
						.image{
							background-size: 200px;
						    height: 470px;
							background-position: bottom right;
						}
					}
				}
			}

			&.app-section{
				min-height: 617px;
				.slider-container{
					.slider{
						min-height: 439px;
					}
				}
				.text{
					margin-top:15px;
				}
			}
		}

	}

}

@media (max-width:700px){
	.sweet-alert{
		&.customClass{
			width:500px;
			margin-left: -250px;
			min-height: 350px;
		}
		button{
			font-size:15px;
		}
		h2{
			font-size:25px;
		}
		p{
			font-size:15px;
		}
	}
	.home-page{
		.section{
			&.download-section{
				.container{
					.row{
						.stores{
							padding-bottom: 130px;
							padding-top:0;
							width:600px;
							h2{
								width: 100%;
							}
							p{
								width:100%;
							}
						}
						.image-div{
							width:0;
						}
					}
				}
			}
		}
	}
}
div[role="toolbar"] {
    display: none !important;
}
@media(max-width:690px){
	.home-page{
		.section{
			&.download-section{
				.container{
					.row{
						.image{
							height:450px;
							background-size: 184px;
						}
						.stores{
							padding-top:0;
							padding-bottom: 150px;
							h2{
								width:110%;
							}
							p{
								width:100%;
							}
							.buttons{
								max-width: 133px;
								a{
									img{
										max-height: 45px;
									}
								}
							}
						}
					}
				}
			}
			&.app-section{
				min-height: 680px;
				.slider-container{
					.slider{
						min-height: 500px;
					}
				}
			}
		}
	}
	.why-page{
		.why-loans{
			.title{
				padding-left:10px;
				padding-right:10px;
			}
			.pre-container{
				.row{
					flex-direction: column;
					padding-top:0;
					.card{
						margin: 0 auto;
						width: 90%;
						margin-bottom:20px;
					}
				}
			}
		}
	}
}

.tag-btn{
	max-width: 408.55px;
	display: flex;
	justify-content: space-between;
}


@media(max-width:560px){
	.sweet-alert{
		margin-right:0;
		left: 50%;
		right:unset;
		&.customClass{
			width: 400px;
		    margin-left: -200px;
		}
		p{
			max-width:265px;
		}
		h2{
			max-width: 275px;
		}
	}
	.tag-btn{
		margin: 0 auto;
	}
	
	.why-page{
		.why-cards{
			.row{
				.card{
					width:75%
				}
			}
		}
	}
	.home-page{
		.section{
			&.why-section{
				.pre-container{
					.row{
						.card{
							width:75%;
						}
					}
				}
			}

			&.app-section{
				min-height: 510px;
				.slider-container{
					.slider{
						min-height:370px;
					}
				}
				h2{
					max-width:90%;
					font-size:30px;
				}
				.text{
					.quote{
						p{
							font-size:22px;
						}
					}
					.author{
						.name{
							font-size:17px;
						}
					}
				}
			}

			
		}
	}
	footer{
		.container{
			.bottom{
				text-align: center;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}

@media(max-width:500px){

	.careers-page{
		.container{
			.header{
				.title{
					font-size:25px;
				}
				h4{
					font-size:15px;
				}
			}
			.jobs{
				.job{
					.position{
						font-size:15px;
					}
					.description{
						font-size:13px;
					}
					.date{
						font-size:13px;
					}
				}
				
			}
			.soon{
				padding-bottom: 200px;
				padding-top: 99px;
				h2{
					font-size:25px;
				}
				p{
					font-size:15px;
				}
			}
		}
	}
	.miscellaneous-page{
		.container{
			a{
				font-size:15px;
			}
			.content{
				h2{
					font-size:25px;
				}
				.body{
					p{
						font-size:15px;
					}
				}
			}
		}
	}
	.contact-page{
		.contact-header{
			.container{
				padding-right: 15px;
    			padding-left: 15px;
				h2{
					font-size:25px;
				}
				p{
					font-size: 15px;
				}
			}
		}
		.contact-body{
			.pre-container{
				.left{
					h3{
						font-size:21px;
					}
					p{
						font-size:15px;
					}
					a{
						font-size:15px;
					}
				}
				.right{
					h6{
						font-size:17px;
					}
					form{
						padding-right: 10px;
						font-size:13px;
						padding-left: 10px;
						.input-grp{
							input,textarea{
								text-indent:7.7px;
								font-size:13px;						
							}	
						}
						.row{
							.input-grp{
								.label{
									font-size:13px;
								}
								input,textarea{
									font-size:13px;
									text-indent:7.7px;
								}	
							}
						}
						.check-grp{
							align-items: center;
							.checkbox{
								label{
									font-size:13px;
								}
								label:before{
									width: 22px;
									height: 22px;
								}
								label:after{
									top: 10px;
									left: 9px;
									width: 13px;
									height: 7px;
								}
							}
						}
					}
				}
			}
		}
	}
	.news-page{
		.news-header{
			.container{
				padding-right: 15px;
    			padding-left: 15px;
				h2{
					font-size:25px;
				}
				p{
					font-size:15px;
				}
			}
		}
		.featured-news{
			.pre-container{
				.row{
					.card{
						max-width: 300px;
						.content{
							.date{
								font-size:15px;
							}
							.title{
								h5{
									font-size:20px;
								}
							}
							.blurb{
								p{
									font-size:15px;
								}
							}
							.read-more{
								a{
									font-size:15px;
								}
							}
						}
					}
				}
			}
			.load-more{
				padding:0 20px 58.78px 20px;
			}
		}
		
		.news-letter{
			.container{
				.header{
					h2{
						font-size:25px;
					}
					p{
						font-size:15px;
					}
				}
				.content{
					padding: 0 20px;
				}
			}
		}
	}
	.article-page{
		.container{
			padding:0 15px;
			h2{
				font-size:25px;
			}
			a{
				font-size:15px;
			}
			h5{
				font-size:15px;
			}
		}
		.article-body{
			.container{
				p{
					font-size:15px;
				}
				img{
					min-height: unset;
				}
			}
		}
		.social-media{
			padding:0 15px;
			.caption{
				h5{
					font-size: 15px;
				}
			}
		}
		.other-news{
			.pre-container{
				.row{
					.card{
						max-width: 300px;
						.content{
							.date{
								font-size:15px;
							}
							.title{
								h5{
									font-size:20px;
								}
							}
							.blurb{
								p{
									font-size:15px;
								}
							}
							.read-more{
								a{
									font-size:15px;
								}
							}
						}
					}
				}
			}
		}
	}
	.about-page{
		.about-header{
			.container{
				.title{
					padding:0 10px;
					h2{
						font-size:25px;
					}
					p{
						font-size:15px;
					}
					.button{
						margin-top:70px;
					}
				}
			}
		}
		.about-content{
			.container{
				.content{
					font-size:15px;
				}
			}
		}
		.mission-vision{
			.container{
				.left{
					h2{
						font-size:25px;
					}
					.content{
						font-size:15px;
					}
				}
			}
		}
		.competetive-edge{
			padding-top: 30px;
			.container{
				.title{
					padding: 20px;
					h2{
						font-size:25px;
					}
				}
				.row{
					padding-bottom:30px;
					.step{
						.header{
							h5{
								font-size:25px;
							}
						}
						.content{
							p{
								font-size:15px;
							}
						}
					}
				}
			}
		}
	}
	.why-page{
		.why-header{
			.container{
				h2{
					font-size:25px;
				}
				p{
					font-size:15px;
				}
			}
		}
		.why-cards{
			.header{
				.title{
					padding-bottom:0;
					h2{
						font-size:25px;
					}
				}
			}
			.row{
				.card{
					h6{
						font-size:20px;
					}
					p{
						font-size:15px;
					}
				}
			}
		}
		.why-video{
			.title{
				h2{
					font-size:25px;
				}
			}
			.video-parent{
				.video-container{
					.video-player{
						button{
							// top:30%;
							i{
								font-size:70.5px;
							}
						}
					}
					.video-text{
						padding-top: 0;
						h4{
							font-size: 20px;
						}
						p{
							font-size:15px;
						}
					}
				}
			}
		}
		.why-loans{
			.title{
				padding: 30px;
				h2{
					font-size:25px;
				}
			}
			.pre-container{
				.row{
					.card{
						.text{
							.card-title{
								font-size:20px;
							}
							.content{
								font-size:15px;
							}
						}
					}
				}
			}
		}
		.why-contact{
			.container{
				.header{
					h2{
						font-size:25px;
					}
					p{
						font-size:15px;
					}
				}
			}
		}
	}
	footer{
		.container{
			.combine{
				flex-direction: column;
				.menu{
					width:100%;
				}
			}
		}
	}
	.home-page{
		.section{
			&.download-section{
				.container{
					.row{
						.image{
							background-size: 185px;
						}
						.stores{
							padding-bottom: 120px;
							h2{    
								font-size:25px;
								line-height: 1.2;
							}
							.buttons{
								max-width: 100px;
								a{
									img{
										max-height: 40px;
										width:unset;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media(max-width:460px){
	.home-page{
		.section{
			&.app-section{
				.slider-container{
					.slider{
						min-height: 410px;
					}
				}
			}
		}
	}

	// .about-page{
	// 	.competetive-edge{
	// 		.container{
	// 			.title{
	// 				padding:10px 20px;
	// 				h2{
	// 					font-size:35px;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	.why-page{
		.why-contact{
			.container{
				.content{
					padding:40px;
				}
			}
		}
	}
}

@media(max-width:453px){
	.btn{
		&.btn-orange{    
			max-width: 165px;
		}
		&.btn-gradient{    
			max-width: 160px;
		}
	}
}
@media (max-width:450px){
	
	.why-page{
		.why-cards{
			.row{
				.card{
					margin: 5%;
					width:90%;
				}
			}
		}
	}
	.home-page{
		.section{
			&.why-section{
				.pre-container{
					.row{
						.card{
							margin: 5%;
							width:90%;
						}
					}
				}
			}
			&.app-section{
				min-height: 560px;
			}
		}
	}

}


@media (max-width:400px){
	.contact-page{
		.contact-body{
			.pre-container{
				.left{
					.row{
						flex-direction: column;
						align-items: center;
						div{
							width:100%;
						}
					}
				}
			}
		}	
	}
	.why-page{
		.why-loans{
			.pre-container{
				.row{
					.card{
						text-align: center;
						.logo{
							padding-left: 0;
						}
					}
				}
			}
		}
		.why-video{
			.video-parent{
				.video-container{
					.video-player{
						button{
							i{
								font-size:60.5px;
							}
						}
					}
				}
			}
		}
	}
	.home-page{
		.section{
			&.download-section{
				.container{
					.row{
						.image{
							background-size: 142px;
							height: 442px;
						}
						.stores{
							.buttons{
								a{
									img{
										max-height: 30px;
									}
								}
							}
						}
					}
				}
			}
			&.app-section{
				// min-height: 590px;
				.slider-container{
					.slider{
						min-height: 445px;
					}
				}
			}
		}
	}

	.about-page{
		.competetive-edge{
			.container{
				.row{
					.step{
						padding: 0 30px;
					}
				}
			}
		}
	}
}
@media(max-width:353px){
	.btn{
		&.btn-orange{    
			max-width: 292px;
		}
		&.btn-gradient{    
			max-width: 292px;
		}
	}
}

@media (max-width:350px){
	.why-page{
		.why-video{
			.video-parent{
				.video-container{
					.video-player{
						button{
							i{
								font-size: 40.5px;
							}
						}
					}
				}
			}
		}
	}
	
	.tag-btn{
		flex-direction: column;
		align-items: center;
	}
	.home-page{
		.section{
			&.why-section{
				.pre-container{
					.row{
						.card{
						}
					}
				}
			}
			&.download-section{
				.container{
					.row{
						.image{
							height: 444px;
						}
					}
				}
			}
		}
	}
}

@media (max-width:331px){
	.home-page{
		.section{
			&.app-section{
				min-height: 630px;
				.slider-container{
					.slider{
						min-height:490px;						
					}
				}
			}
		}
	}
}


@media (max-width:325px){
	.home-page{
		.section{
			&.app-section{
				.slider-container{
					.slider{
						min-height:510px;						
					}
				}
			}
		}
	}
}